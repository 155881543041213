import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {ENV} from "../environments/env";

export class SsoTokenResponse {
    token: string;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private jwtToken: string | null = null;  // Global variable to store JWT token

    constructor(private http: HttpClient) {}

    login(username: string, password: string, otp: string): Observable<void> {
        return this.http.post<{ token: string }>(ENV.apiBaseUrl + '/account/internal-login', {
            username: username,
            password: password,
            otp: otp
        }).pipe(map(response => {
            localStorage.setItem('jwtToken', response.token);
        }));
    }

    getJwtToken(): string | null {
        return localStorage.getItem('jwtToken');
    }

    getAppSsoToken(merchantId: string, clientId: string): Observable<SsoTokenResponse> {
        const headers = this.addAuthorizationHeader();
        const ssoTokenResponseObservable = this.http.get<SsoTokenResponse>(ENV.apiBaseUrl + `/internal-admin-api/merchants/${merchantId}/apps/${clientId}/sso-token`, { headers });
        return ssoTokenResponseObservable;
    }

    private addAuthorizationHeader(): HttpHeaders {
        const headers = new HttpHeaders();
        const jwtToken = this.getJwtToken();
        const updatedHeaders = headers.set('Authorization', 'Bearer ' + jwtToken);
        return updatedHeaders;
    }
    public getBearerHeader(): HttpHeaders {
        return this.addAuthorizationHeader();
    }
}
