import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloverValuePipe } from './clover-value.pipe';


@NgModule({
  imports: [
    CommonModule
    
  ],
  declarations: [
    CloverValuePipe,
  ], exports: [
    CloverValuePipe,
  ], providers: [CloverValuePipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }

