import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { forkJoin } from 'rxjs';
import { IdNameModel } from '../app.service';
import { CloverValuePipeCorePipe, CloverValueType } from '../core/clover-value.pipe';
import { Category, Field, ItemEx, ItemFieldCollections, ItemsService, ScanDataProgram, Tag } from '../items.service';

@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.scss']
})
export class EditItemComponent implements OnInit {
  @ViewChild(NgForm, { static: false }) frm: NgForm;

  public CloverValueType = CloverValueType;

  wasValidated = false;
  itemId: string;
  item: ItemEx;
  copy: boolean;
  close: boolean;
  fields: Field[];
  itemFieldCollections: ItemFieldCollections;
  selectedCategory: Category;
  availableCategories: Category[];
  selectedTag: Tag;
  availableTags: Tag[];

  saving = false;
  selectedScanDataProgram: ScanDataProgram;

  constructor(private itemsService: ItemsService, private router: Router, private route: ActivatedRoute,
              public cloverValueFormatter: CloverValuePipeCorePipe) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.itemId = this.route.snapshot.paramMap.get('itemId');
    this.copy = this.route.snapshot.queryParams.copy;
    this.close = this.route.snapshot.queryParams.close;

    forkJoin([
      this.itemsService.getItem(this.itemId),
      this.itemsService.getFields(),
      this.itemsService.getItemFieldCollections()
    ]).subscribe(([item, fields, itemFieldCollections]) => {
      if (!item.itemStock) {
        item.itemStock = { quantity: 0 };
      }
      this.fields = fields;
      this.itemFieldCollections = itemFieldCollections;

      this.item = item;
      this.updateCalculatedProperties();
    });
  }

  get itemPriceTypeName(): string {
    switch (this.item.priceType) {
      case 'FIXED':
        return 'Fixed';
      case 'VARIABLE':
        return 'Variable';
      case 'PER_UNIT':
        return 'Per Unit';
    }
    return '';
  }

  getUserFieldValue(field: Field): string {
    return this.item.userFields.find(uf => uf.id == field.id).value;
  }
  setUserFieldValue(field: Field, newValue: string): void {
    let uf = this.item.userFields.find(uf => uf.id == field.id);
    if (!uf) {
      uf = { id: field.id, fieldType: field.fieldType, value: newValue };
      this.item.userFields.push(uf);
    } else {
      uf.value = newValue;
    }
  }
  userFieldsValid(): boolean {
    return this.fields
        .filter(field => field.optionType == 'required')
        .filter(field => ['text'].indexOf(field.fieldType) >= 0)
        .every(field => !!this.getUserFieldValue(field)); // Use double negation to convert to boolean
  }

  scanDataFieldsValid(): boolean {
    if (!this.item.scanDataProgram) {
      return true;
    }

    return this.item.categories.length
      && this.item.manufacturer.length
      && this.item.uom.length
      && this.item.innerQuantity != null;

  }

  typeaheadOnSelect(list: any[], val: TypeaheadMatch) {
    if (val.item) {
      // this.item.categories.push(val.item);
      list.push(val.item);
      this.selectedCategory = null;
      this.selectedTag = null;
      this.updateCalculatedProperties();
    }
  }
  removeFromList(list: any[], item: any) {
    let idx = list.findIndex(i => i.id == item.id);
    if (idx >= 0) {
      list.splice(idx, 1);
      this.updateCalculatedProperties();
    }
  }
  updateCalculatedProperties() {
    this.availableCategories = this.itemFieldCollections.categories
      .filter(c => !this.item.categories.some(ic => ic.id == c.id));

    this.availableTags = this.itemFieldCollections.tags
      .filter(c => !this.item.tags.some(ic => ic.id == c.id));

    this.selectedScanDataProgram = this.itemFieldCollections.scanDataPrograms.find(i => i.id == this.item.scanDataProgram);
    if (!this.selectedScanDataProgram) {
      this.selectedScanDataProgram = this.itemFieldCollections.scanDataPrograms[0];
    }
  }
  selectScanDataProgram(p: ScanDataProgram) {
    this.item.scanDataProgram = p.id;
    this.updateCalculatedProperties();
  }

  save(): void {
    this.wasValidated = true;
    if (!this.frm.valid || !this.userFieldsValid() || !this.scanDataFieldsValid()) {
      return;
    }
    if (this.saving) {
      return;
    }
    this.saving = true;
    if (this.copy) {
      this.item.id = null;
    }
    this.itemsService.storeItem(this.item).subscribe(_ => {
      if (this.close) {
        window.close();
      }
      else {
        this.itemsService.navigateToInventory();
      }

    });
  }
  cancel() {
    if (this.saving) {
      return;
    }
    if (this.close) {
      window.close();
    }
    else {
      this.itemsService.navigateToInventory();
    }
  }
}
