import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ListComponent} from './list/list.component';
import {CustomTypeahead} from './core/custom-typeahead';
import {AppService} from './app.service';
import {EditItemComponent} from './edit-item/edit-item.component';
import {EditSelectedItemsComponent} from './edit-selected-items/edit-selected-items.component';

import {SharedModule} from './shared.module';
import {DataTablesModule} from 'angular-datatables';
import {CookieService} from 'ngx-cookie-service';
import {ComponentLoaderFactory, PositioningService, TypeaheadContainerComponent, TypeaheadModule} from 'ngx-bootstrap';
import {TypeaheadConfig} from 'ngx-bootstrap/typeahead';
import {CloverValuePipeCorePipe} from './core/clover-value.pipe';
import {IndeterminateDirective} from './core/indeterminate';
import { Renderer2, RendererFactory2 } from '@angular/core';

// import {CloverValuePipe} from './clover-value.pipe';
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        ListComponent,
        EditItemComponent,
        EditSelectedItemsComponent,
        CustomTypeahead,
        CloverValuePipeCorePipe,
        IndeterminateDirective,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        SharedModule,
        DataTablesModule,
        TypeaheadModule.forRoot(),
    ],
    providers: [
        CookieService,
        AppService,
        ComponentLoaderFactory,
        PositioningService,
        TypeaheadConfig,
        CloverValuePipeCorePipe,
        ListComponent,
        // Renderer2,
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        TypeaheadContainerComponent,
    ],
})
export class AppModule {
}

