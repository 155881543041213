import { HostListener, Directive, Input, ElementRef } from '@angular/core';
import { TypeaheadDirective } from 'ngx-bootstrap/typeahead';

@Directive({
  selector: '[custom-typeahead]',
})
export class CustomTypeahead extends TypeaheadDirective {
  @Input() typeaheadShowAllOnFocus: boolean;
  @Input('custom-typeahead')
  set customTypeahead(data: any) {
    this.typeahead = data;
  }

  @HostListener('focus')
  onFocus(): void {
    if (this.typeaheadShowAllOnFocus) {
      if (this.typeaheadMinLength === 0) {
        this.typeaheadLoading.emit(true);
        this.keyUpEventEmitter.emit('');
        let t: any = this;
        var el: ElementRef = t.element;
        el.nativeElement.select();
      }
    } else {
      super.onFocus();
    }
  }
}
