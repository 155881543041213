import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import {AuthService} from "../auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  error: string;
  loading = false;
  step = 1;
  returnUrl: string;

  username: string;
  password: string;
  otp: string;



  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {
  }

  ngOnInit() {


    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmitStep1() {
    this.step += 1;
  }
  onSubmitStep2() {
    this.authService.login(this.username, this.password, this.otp).subscribe(
      data => {        
        this.router.navigateByUrl("/internal-admin/dashboard")
      },
      error => {
        console.log(error);
        this.error = error.statusText;
        this.loading = false;
      }
    )

    //this.loading = true;
    // this.authenticationService.login(this.f.username.value, this.f.password.value)
    //     .pipe(first())
    //     .subscribe(
    //         data => {
    //             this.router.navigate([this.returnUrl]);
    //         },
    //         error => {
    //             this.error = error;
    //             this.loading = false;
    //         });
  }
}
