import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, ReplaySubject, Subscription} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from './auth.service';
import {ENV} from "../environments/env";


export enum FieldType {
    Text = 'text',
    Numeric = 'numeric',
    Date = 'date',
    YesNo = 'yesNo',
    Dropdown = 'dropdown',
    Image = 'image',
    Radio = 'radio'
}

export enum FieldState {
    Required = 'required',
    Optional = 'optional',
    Off = 'off'
}

export interface UserDefinedFieldValue {
    id: string;
    value: any;
    fieldType: FieldType;
}

export interface TaxRate {
    id: number;
    isDefault: boolean;
    rate: number;
    name: string;
    taxAmount: number;
}

export interface Category {
    id: string;
    name: string;
}

export interface Tag {
    id: string;
    name: string;
}

export interface ItemStock {
    quantity: number;
}

export interface ItemEx {
    id?: string;
    name?: string;
    alternateName?: string;
    price?: number;
    priceType?: string;
    itemStock?: ItemStock;
    code?: string;
    sku?: string;
    defaultTaxRates?: boolean;
    taxRates?: TaxRate[];
    categories?: Category[];
    tags?: Tag[];
    userFields?: Array<UserDefinedFieldValue>;
    scanDataProgram?: number;

    manufacturer?: string;
    uom?: string;
    innerQuantity?: number;
    isRevenue?: boolean;
    hidden?: boolean;
    cost?: number;
    unitName?: string;
}

export interface UpdateMultipleItemsModel {
    ids: string[],
    scanDataProgram?: number;
    addCategories?: Category[];
    removeCategories?: Category[];
    addTags?: Tag[];
    removeTags?: Tag[];
    manufacturer: string;
    uom: string;
    innerQuantity: number;
    isRevenue: boolean;
    hidden: boolean;
}

export interface Field {
    id: string;
    // requiredOnly: boolean;
    fieldType?: FieldType;
    optionType?: FieldState;
    name: string;
    minLength?: number;
    maxLength?: number;
    dateFormat?: string;
    items?: Array<string>;
    nestedPropertyName?: string;
    visible?: boolean;
    exportOnly?: boolean;
    displayOnly?: boolean;
}

interface FieldSettings {
    fields: Field[];
}

export interface ScanDataProgram {
    id: number;
    name: string;
}

export interface ItemFieldCollections {
    categories: Category[];
    scanDataPrograms: ScanDataProgram[];
    tags: Tag[];
}

export interface FetchItemsParam {
    scanDataProgramId?: number;
    categoryId?: string;
}

const mockItemFieldCollections: ItemFieldCollections = {
    categories: [],
    scanDataPrograms: [
        {
            id: 0,
            name: 'NONE'
        },
        {
            id: 1,
            name: 'ALTRIA'
        },
        {
            id: 2,
            name: 'RAITMS'
        }
    ],
    tags: [] // You can add any mock data for tags here if needed
};

const mockFieldSettings: FieldSettings = {
    fields: []
};

const mockFields: Field[] = [];

@Injectable({
    providedIn: 'root'
})
export class ItemsService {


    private cachedItems: ItemEx[];
    private cachedItemsRqUrl: string;
    private cachedItemsParam: FetchItemsParam;
    private items$ = new ReplaySubject<ItemEx[]>(1);
    private readonly itemFields$ = new ReplaySubject<Field[]>(1);
    private readonly itemFieldCollections$ = new BehaviorSubject<ItemFieldCollections>(null);


    constructor(private http: HttpClient, private router: Router, private authService: AuthService, private route: ActivatedRoute) {
        console.log('new ItemsService');
        setTimeout(() => {
            this.itemFields$.next(mockFieldSettings.fields);
        }, 0);
    }

    subscribeToItemChanges(callback: (value: ItemEx[]) => void): Subscription {
        return this.items$.subscribe(callback);
    }

    getItems(): Observable<ItemEx[]> {
        let opCompleteSubject = new ReplaySubject<ItemEx[]>(1);
        let sub = this.items$.asObservable().pipe(first()).subscribe(items => {
            setTimeout(() => sub.unsubscribe());
            opCompleteSubject.next(items);
            opCompleteSubject.complete();
        });
        return opCompleteSubject;
    }

    fetchItems(p?: FetchItemsParam): void {
        const headers = this.authService.getBearerHeader();
        var rqUrl = ENV.inventoryBaseUrl + '/master/item/';
        let params = new URLSearchParams();
        if (p) {
            for (let key in p) {
                params.set(key, p[key]);
            }
            rqUrl += '?' + params.toString();
        }
        if (this.cachedItemsRqUrl == rqUrl) {
            return;
        }
        this.cachedItemsRqUrl = rqUrl;
        this.cachedItemsParam = p;
        this.http.get<ItemEx[]>(rqUrl, {headers}).subscribe(items => {
            this.cachedItems = items;
            this.items$.next(items);
        });
    }

    getItem(id: string): Observable<ItemEx> {
        console.log(id);
        const headers = this.authService.getBearerHeader();
        return this.http.get<ItemEx>(ENV.inventoryBaseUrl + '/master/item/' + id, {headers});
    }

    storeItem(item: ItemEx): Observable<void> {
        const opCompleteSubject = new ReplaySubject<void>(1);
        const headers = this.authService.getBearerHeader();

        let httpAction = item.id
            ? this.http.put<ItemEx>(ENV.inventoryBaseUrl + '/master/item/' + item.id, item, {headers})
            : this.http.post<ItemEx>(ENV.inventoryBaseUrl + '/master/item', item, {headers});

        httpAction.subscribe(storedItem => {
            if (this.cachedItems) {
                if (item.id) {
                    var itemIdx = this.cachedItems.findIndex(i => i.id == storedItem.id);
                    this.cachedItems[itemIdx] = storedItem;
                } else {
                    this.cachedItems.push(storedItem);
                }
                this.items$.next(this.cachedItems);
            }

            opCompleteSubject.next(null);
            opCompleteSubject.complete();
        });

        return opCompleteSubject.asObservable();
    }

    updateMultipleItems(m: UpdateMultipleItemsModel): Observable<void> {
        const opCompleteSubject = new ReplaySubject<void>(1);
        const headers = this.authService.getBearerHeader();

        this.http.put<ItemEx[]>(ENV.inventoryBaseUrl + '/master/item', m, {headers}).subscribe(storedItems => {
            if (this.cachedItems) {
                storedItems.forEach(storedItem => {
                    var itemIdx = this.cachedItems.findIndex(i => i.id == storedItem.id);
                    this.cachedItems[itemIdx] = storedItem;
                });
                this.items$.next(this.cachedItems);
            }

            opCompleteSubject.next(null);
            opCompleteSubject.complete();
        });

        return opCompleteSubject.asObservable();
    }

    deleteItem(id: string): Observable<void> {
        const headers = this.authService.getBearerHeader();
        const opCompleteSubject = new ReplaySubject<void>(1);
        this.http.delete<void>(ENV.apiBaseUrl + '/master/item/' + id, {headers}).subscribe(_ => {
            var itemIdx = this.cachedItems.findIndex(i => i.id == id);
            if (itemIdx >= 0) {
                this.cachedItems.splice(itemIdx, 1);
                this.items$.next(this.cachedItems);
            }

            opCompleteSubject.next(null);
            opCompleteSubject.complete();
        });

        return opCompleteSubject.asObservable();
    }

    getFields(): Observable<Field[]> {
        let opCompleteSubject = new ReplaySubject<Field[]>(1);
        let sub = this.itemFields$.asObservable().pipe(first()).subscribe(fields => {
            setTimeout(() => sub.unsubscribe());
            opCompleteSubject.next(fields);
            opCompleteSubject.complete();
        });
        return opCompleteSubject;
    }

    getItemFieldCollections(): Observable<ItemFieldCollections> {
        const opCompleteSubject = new ReplaySubject<ItemFieldCollections>(1);

        if (this.itemFieldCollections$.getValue() == null) {
            // Using setTimeout to simulate asynchronous behavior (similar to HTTP request)
            setTimeout(() => {
                this.itemFieldCollections$.next(mockItemFieldCollections);
                opCompleteSubject.next(mockItemFieldCollections);
                opCompleteSubject.complete();
            }, 0);
        } else {
            const sub = this.itemFieldCollections$.asObservable().pipe(first()).subscribe(r => {
                setTimeout(() => sub.unsubscribe());
                opCompleteSubject.next(r);
                opCompleteSubject.complete();
            });
        }
        return opCompleteSubject;
    }

    selectedRowsData: any[];

    setSelectedItems(data: any[]) {
        console.log('selected:', data.length);
        this.selectedRowsData = data;
    }

    getSelectedItems(): any[] {
        return this.selectedRowsData;
    }

    navigateToInventory() {
        console.log(this.cachedItemsParam);

        // Get the current route's URL
        const currentUrl = this.router.url;

        // Construct the URL with the same route and updated query parameters
        const urlWithQueryParams = this.router.serializeUrl(
            this.router.createUrlTree(['/list'], { queryParams: this.cachedItemsParam })
        );
        // Navigate to the updated URL and force a page reload
        this.router.navigateByUrl(urlWithQueryParams).then(() => {
            window.location.reload();
        });
    }
}
