import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import {ENV} from "../environments/env";

export interface Merchant {
  name: string;
  basicMerchantInfoSynchronized: boolean;
  historicalDataSynchronized: boolean;
  appInstalled: boolean;
}
export interface MerchantSettings {
  showYellowBox: boolean;
}

export interface Employee {
  id: string,
  name: string;
  role: string;
  email: string;
  isOwner: boolean;
}

export interface UserProfile {
  merchant: Merchant;
  employee: Employee;
}

export enum AppAlertType {
  Success = "success",
  Warning = "warning",
  Error = "error",
}
export interface AppAlert {
  id?: string;
  type: AppAlertType;
  msg: string;
  timeout?: number;
}

export interface IdNameModel {
  id: string;
  name: string;
}


export interface ApiEnvironmentSettings {
  settings: MerchantSettings;
}

const mockApiEnvironmentSettings: ApiEnvironmentSettings = {
  settings: {
    showYellowBox: false
  }
};

@Injectable()
export class AppService {
  constructor(private http: HttpClient) { }

  private apiEnvironmentSource = new BehaviorSubject<ApiEnvironmentSettings>(null);
  apiEnvironment$ = this.apiEnvironmentSource.asObservable();

  private pageTitleSource = new Subject<string>();
  private isFirstEnvCheck = true;
  pageTitle$ = this.pageTitleSource.asObservable();
  setPageTitle(title: string) {
    var t = this;
    //if called directly we get
    //ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'null: undefined'. Current value: 'null: Rules'.
    setTimeout(function () {
      t.pageTitleSource.next(title);
    }, 50);
  }

  private appAlertSource = new Subject<AppAlert>();
  appAlert$ = this.appAlertSource.asObservable();
  addAppAlert(appAlert: AppAlert) {
    this.appAlertSource.next(appAlert);
  }
  removeAppAlert(id: string) {
    this.appAlertSource.next({ id: id, type: AppAlertType.Success, msg: null, timeout: 0 });
  }




  // getUserProfile(): Observable<UserProfile> {
  //   return this.http.get<UserProfile>(ENV.apiBaseUrl + "/api/environment");
  // }


  onAuthenticated() {
    // Оновлюємо apiEnvironmentSource мок даними
    this.apiEnvironmentSource.next(mockApiEnvironmentSettings);
  }

  doNotShowYellowBoxAgain() {
    var s = this.apiEnvironmentSource.getValue();
    //if (null == s) ???
    s.settings.showYellowBox = false;
    this.http.put<MerchantSettings>(ENV.apiBaseUrl + "/settings", s.settings).subscribe(updatedSettings => {
      s.settings = updatedSettings;
      this.apiEnvironmentSource.next(s);
    });
  }
}
