import { Pipe, PipeTransform, LOCALE_ID, Directive, OnInit, ElementRef, ɵConsole } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';


export enum CloverValueType {
  AsIs = 1,
  Money = 2,
  Percent = 3
}

//Money amounts in Clover are always passed as cent values. An amount or total of 2099 would be equivalent to $20.99 for a merchant using the US dollar.
//https://docs.clover.com/build/working-with-orders/
@Pipe({ name: 'CloverValuePipeCore' })
export class CloverValuePipeCorePipe implements PipeTransform {
  private _locale = "en_US";
  transform(value: any, valueType: CloverValueType, digits?: string): string {
    switch (valueType) {
      case CloverValueType.AsIs: //count
        if (typeof value === "undefined")
          return "";
        var dp = new DecimalPipe(this._locale)
        return dp.transform(value, "0.0-0");
      case CloverValueType.Money: //money
        if (typeof value === "undefined")
          return "";
        var cp = new CurrencyPipe(this._locale);
        digits = digits || "0.0-0";
        return cp.transform(value / 100, undefined, undefined, digits);
      case CloverValueType.Percent: //percent
        if (typeof value === "undefined")
          return "";
        var dp = new DecimalPipe(this._locale)
        return dp.transform(value, "0.0-0") + "%";
    }
  }

  formatMoney(value: any): string {
    var dp = new DecimalPipe(this._locale)
    return dp.transform(value / 100, "0.0-2");
  }

  parseMoney(value: any): number {
    value = value.toString().replace(/[$,]/g, "");
    if (!isFinite(value * 1)){
      return undefined;
    }
    return Number(value) * 100;
  }
}

