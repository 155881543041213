import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { IdSrvService } from '../id-srv.service';
import { CloverValueType } from '../clover-value.pipe';
import { Subject } from 'rxjs';
import {AuthService} from "../auth.service";
import {Merchant} from "../model/merchant";
import {ENV} from "../../environments/env";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  CloverValueType = CloverValueType;
  merchants: Merchant[];
  dtOptions: DataTables.Settings = {};
  // We use this trigger because fetching the list of persons can be quite long,
  // thus we ensure the data is fetched before rendering
  dtTrigger = new Subject();

  constructor(private router: Router, private idSrvService: IdSrvService, private authService: AuthService) { }

  ngOnInit() {
    this.dtOptions = {
    };
    this.idSrvService.getMerchants().subscribe(
      data => {
        this.merchants = data;
        this.dtTrigger.next();
      },
      error => {
        console.log(error);
        this.router.navigateByUrl("/internal-admin/login");
      }
    )
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  loginWebApp(merchantId: string, clientId: string) {
    event.stopPropagation();
    this.authService.getAppSsoToken(merchantId, clientId).subscribe(r => {
      window.open(ENV.authBaseUrl + "/account/ssologin?t=" + encodeURIComponent(r.token));
    })
    return false;
  }
}
