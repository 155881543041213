import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {forkJoin} from 'rxjs';
import {IndeterminateDirective} from '../core/indeterminate';
// tslint:disable-next-line:max-line-length
import {
    Category,
    Field,
    ItemEx,
    ItemFieldCollections,
    ItemsService,
    ScanDataProgram,
    Tag,
    UpdateMultipleItemsModel
} from '../items.service';

interface CategoryModel extends Category {
    itemCount?: number;
    modified?: boolean;
}

interface TagModel extends Tag {
    itemCount?: number;
    modified?: boolean;
}

@Component({
    selector: 'app-edit-selected-items',
    templateUrl: './edit-selected-items.component.html',
    styleUrls: ['./edit-selected-items.component.scss']
})
export class EditSelectedItemsComponent implements OnInit {
    @ViewChild(NgForm, {static: false}) frm: NgForm;

    selectedItems: ItemEx[];
    fields: Field[];
    itemFieldCollections: ItemFieldCollections;
    saving: boolean = false;
    wasValidated = false;
    selectedScanDataProgram: ScanDataProgram;
    scanDataPrograms: ScanDataProgram[];

    selectedCategory: Category;
    availableCategories: CategoryModel[];
    selectedCategories: CategoryModel[];
    removeCategories: CategoryModel[];

    selectedTag: Tag;
    availableTags: TagModel[];
    selectedTags: TagModel[];
    removeTags: TagModel[];

    manufacturer: string;
    uom: string;
    innerQuantity: number;
    isRevenue?: boolean;
    hidden?: boolean;

    loading: boolean = true;

    constructor(private itemsService: ItemsService, private router: Router, private indeterminateDirective: IndeterminateDirective) {
    }

    ngOnInit(): void {
        this.selectedItems = this.itemsService.getSelectedItems();
        if (!this.selectedItems) {
            this.itemsService.navigateToInventory();
            return;
        }
        console.log(this.selectedItems);

        forkJoin([
            this.itemsService.getFields(),
            this.itemsService.getItemFieldCollections()
        ]).subscribe(([fields, itemFieldCollections]) => {
            this.fields = fields;
            this.itemFieldCollections = itemFieldCollections;
            this.scanDataPrograms = [...this.itemFieldCollections.scanDataPrograms];
            this.scanDataPrograms.unshift({id: null, name: 'Multiple Values'});

            let programId = this.selectedItems[0].scanDataProgram;
            this.manufacturer = this.selectedItems[0].manufacturer;
            this.uom = this.selectedItems[0].uom;
            this.innerQuantity = this.selectedItems[0].innerQuantity;
            this.isRevenue = this.selectedItems[0].isRevenue;
            this.hidden = this.selectedItems[0].hidden;

            let catDict = {};
            this.selectedCategories = [];
            this.removeCategories = [];
            let tagDict = {};
            this.selectedTags = [];
            this.removeTags = [];
            for (let item of this.selectedItems) {
                if (item.scanDataProgram != programId) {
                    programId = null;
                }
                if (item.manufacturer != this.manufacturer) {
                    this.manufacturer = null;
                }
                if (item.uom != this.uom) {
                    this.uom = null;
                }
                if (item.innerQuantity != this.innerQuantity) {
                    this.innerQuantity = null;
                }

                if (item.hidden != this.hidden) {
                    this.hidden = null;
                }
                if (item.isRevenue != this.isRevenue) {
                    this.isRevenue = null;
                }


                //categories
                if (!item.categories) {
                    item.categories = [];
                }

                for (let itemCat of item.categories) {
                    let cat: CategoryModel = catDict[itemCat.id];
                    if (!cat) {
                        cat = {...itemCat};
                        cat.itemCount = 1;
                        catDict[itemCat.id] = cat;
                        this.selectedCategories.push(cat);
                    } else {
                        cat.itemCount += 1;
                    }
                }

                //tags
                if (!item.tags) {
                    item.tags = [];
                }

                for (let itemTag of item.tags) {
                    let tag: TagModel = tagDict[itemTag.id];
                    if (!tag) {
                        tag = {...itemTag};
                        tag.itemCount = 1;
                        tagDict[itemTag.id] = tag;
                        this.selectedTags.push(tag);
                    } else {
                        tag.itemCount += 1;
                    }
                }
            }
            this.selectedScanDataProgram = this.scanDataPrograms.find(i => i.id == programId);
            this.updateStuff();

            this.loading = false;

        });
    }

    typeaheadOnSelect(collectionType: string, val: TypeaheadMatch) {
        if (!val.item) {
            return;
        }

        switch (collectionType) {
            case 'category':
                let cat: CategoryModel = val.item;
                let selectedCat = this.selectedCategories.find(i => i.id == cat.id);
                if (!selectedCat) {
                    selectedCat = {...cat};
                    this.selectedCategories.push(selectedCat);
                }
                selectedCat.itemCount = this.selectedItems.length;
                selectedCat.modified = true;
                this.selectedCategory = null;
                break;
            case 'tag':
                let tag: TagModel = val.item;
                let selectedTag = this.selectedTags.find(i => i.id == tag.id);
                if (!selectedTag) {
                    selectedTag = {...tag};
                    this.selectedTags.push(selectedTag);
                }
                selectedTag.itemCount = this.selectedItems.length;
                selectedTag.modified = true;
                this.selectedTag = null;
                break;
        }

        this.updateStuff();
    }

    removeFromList(collectionType: string, item: any) {
        switch (collectionType) {
            case 'category':
                var idx = this.selectedCategories.findIndex(i => i.id == item.id);
                if (idx >= 0) {
                    this.selectedCategories.splice(idx, 1);
                    this.updateStuff();
                    this.removeCategories.push(item);
                }
                break;
            case 'tag':
                var idx = this.selectedTags.findIndex(i => i.id == item.id);
                if (idx >= 0) {
                    this.selectedTags.splice(idx, 1);
                    this.updateStuff();
                    this.removeTags.push(item);
                }
                break;
        }
    }

    updateStuff() {
        this.availableCategories = this.itemFieldCollections.categories
            .filter(c => !this.selectedCategories.some(ic => ic.id == c.id && ic.itemCount == this.selectedItems.length));
        this.selectedCategories.sort((a, b) => a.name.localeCompare(b.name));

        this.availableTags = this.itemFieldCollections.tags
            .filter(c => !this.selectedTags.some(ic => ic.id == c.id && ic.itemCount == this.selectedItems.length));
        this.selectedTags.sort((a, b) => a.name.localeCompare(b.name));
    }


    save(): void {
        console.log('save()');
        this.wasValidated = true;
        if (!this.frm.valid)
        {
            console.log('');
            return;
        }
        if (this.saving) {
            console.log('');
            return;
        }
        this.saving = true;
        console.log('');

        let m: UpdateMultipleItemsModel = {
            ids: this.selectedItems.map(i => i.id),
            scanDataProgram: this.selectedScanDataProgram.id,
            addCategories: this.selectedCategories.filter(i => i.modified),
            removeCategories: this.removeCategories.filter(i => !this.selectedCategories.some(sc => sc.id == i.id)),

            addTags: this.selectedTags.filter(i => i.modified),
            removeTags: this.removeTags.filter(i => !this.selectedTags.some(sc => sc.id == i.id)),

            manufacturer: this.manufacturer,
            uom: this.uom,
            innerQuantity: this.innerQuantity,
            isRevenue: this.isRevenue,
            hidden: this.hidden
        };
        console.log('');

        this.itemsService.updateMultipleItems(m).subscribe(_ => {
            this.itemsService.navigateToInventory();
        });
        console.log('end save()');
    }

    cancel() {
        if (this.saving) {
            return;
        }
        this.itemsService.navigateToInventory();
    }
}
