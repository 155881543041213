import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {EditSelectedItemsComponent} from './edit-selected-items/edit-selected-items.component';
import {EditItemComponent} from './edit-item/edit-item.component';
import {ListComponent} from './list/list.component';


const routes: Routes = [
    {path: 'internal-admin/login', component: LoginComponent},
    {path: 'internal-admin/dashboard', component: DashboardComponent},
    {path: 'list', component: ListComponent},
    {path: 'inventory/:itemId', component: EditItemComponent},
    // {path: 'edit-selected-items', component: EditSelectedItemsComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
