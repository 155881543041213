// tslint:disable-next-line:max-line-length
import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ViewEncapsulation,
    TemplateRef,
    AfterViewInit,
    ElementRef,
    Renderer2,
    RendererFactory2
} from '@angular/core';
import {ItemsService, Field, FieldState, FieldType, ItemEx, ItemFieldCollections, Category} from '../items.service';
import {ActivatedRoute, Router} from '@angular/router';
import {forkJoin, Subject, Subscription, of, combineLatest, Observable} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {AppService} from '../app.service';
import {ModalModule, BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CurrencyPipe, DecimalPipe} from '@angular/common';
import {first} from 'rxjs/operators';
import {TypeaheadDirective, TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {TypeaheadContainerComponent} from 'ngx-bootstrap';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ListComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(DataTableDirective, {static: false}) dataTable: DataTableDirective;
    @ViewChild(DataTableDirective, {static: false}) dtElement: DataTableDirective;
    @ViewChild('typeahead', {static: true}) typeahead: TypeaheadDirective;
    //@ViewChild('categoryFilter', { static: false }) categoryFilter: ElementRef;

    // Must be declared as "any", not as "DataTables.Settings"
    dtOptions: Promise<DataTables.Settings>;
    dtTrigger: Subject<any> = new Subject();

    fields: Field[];
    currentImgSrc: string;
    modalRef: BsModalRef;
    itemsSubscription: Subscription;
    tableRendered = false;
    private renderer: Renderer2;

    itemFieldCollections: ItemFieldCollections;
    selectedFilterItem: any;
    availableFilterItems: any[];
    hasSelectedRows: boolean = false;
    editSelectedClicked: boolean = false;

    constructor(private route: ActivatedRoute, private itemsService: ItemsService, private router: Router,
                private appService: AppService,
                private rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    ngOnInit() {
        const inventoryDtOptionsVersion = '4';
        if (inventoryDtOptionsVersion != localStorage.getItem('inventoryDtOptionsVersion')) {
            localStorage.clear();
            localStorage.setItem('inventoryDtOptionsVersion', inventoryDtOptionsVersion);
        }

        console.log('getFields subscribe');
        this.dtOptions = forkJoin([this.itemsService.getItemFieldCollections(), this.itemsService.getFields()])
            .toPromise().then(([itemFieldCollections, fields]) => {
                console.log('getFields loaded');

                this.itemFieldCollections = itemFieldCollections;
                this.availableFilterItems = [{
                    id: '',
                    name: 'All Categories',
                    filterType: 'All Categories'
                }].concat(this.itemFieldCollections.scanDataPrograms.map(i => {
                    return {id: i.id.toString(), name: i.name, filterType: 'Scan Data Program'};
                }).concat(this.itemFieldCollections.categories.map(i => {
                    return {id: i.id, name: i.name, filterType: 'Category'};
                })));
                this.route.queryParams.subscribe(params => {
                    var item = this.availableFilterItems.find(i => i.id == params.categoryId || i.id == params.scanDataProgramId);
                    if (item) {
                        this.selectedFilterItem = item.name;
                    }
                    this.itemsService.fetchItems(params);
                });
                return this.initDataTable(fields);
            });

    }

    ngAfterViewInit(): void {
        this.itemsSubscription = this.itemsService.subscribeToItemChanges(_ => {
            console.log('onItemsChanged');
            console.log(this.dtElement);
            if (this.tableRendered) {
                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    dtInstance.ajax.reload();
                });
            } else {
                this.dtTrigger.next();
                this.tableRendered = true;
                let self = this;

                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                    var groupFilterContainer = $('.groupFilterContainer')[0];
                    //this.renderer.appendChild(groupFilterContainer, this.categoryFilter.nativeElement);
                    let dti: any = dtInstance;
                    dti.on('click', 'th.select-checkbox', function() {
                        if ($('th.select-checkbox').hasClass('selected')) {
                            dti.rows({filter: 'applied'}).deselect();
                            $('th.select-checkbox').removeClass('selected');
                            self.hasSelectedRows = false;
                        } else {
                            dti.rows({filter: 'applied'}).select();
                            $('th.select-checkbox').addClass('selected');
                            self.hasSelectedRows = true;
                        }
                        var selectedRows = dtInstance.rows({selected: true});
                        self.hasSelectedRows = selectedRows.count() > 1;
                    }).on('select deselect', function(e, dt, type, indexes) {
                        //ignore deselect, dtSelect calls deselect when table is being destroyed,
                        //we want to preserve selection and pass selected items to edit-selected-items component
                        if (self.editSelectedClicked) {
                            return;
                        }
                        var selectedRows = dtInstance.rows({selected: true});
                        if (selectedRows.count() !== dtInstance.rows().count()) {
                            $('th.select-checkbox').removeClass('selected');
                        } else {
                            $('th.select-checkbox').addClass('selected');
                        }
                        self.hasSelectedRows = selectedRows.count() > 1;
                        self.itemsService.setSelectedItems(selectedRows.data().toArray().map(i => i[0]));
                    });
                });
            }
        });
    }


    initDataTable(fields: Field[]): DataTables.Settings {
        this.fields = fields.filter(f => f.optionType != FieldState.Off && f.fieldType != FieldType.Image);
        this.fields.unshift({visible: false, id: 'hidden', name: 'Hidden'});
        this.fields.unshift({visible: false, id: 'isRevenue', name: 'Revenue Item'});
        this.fields.unshift({visible: false, id: 'innerQuantity', name: 'Individual Consumable Units'});
        this.fields.unshift({visible: true, id: 'uom', name: 'UOM'});
        this.fields.unshift({visible: true, id: 'manufacturer', name: 'Manufacturer'});
        this.fields.unshift({visible: true, id: 'scanDataProgram', name: 'Scan Data Program',});
        this.fields.unshift({visible: true, id: 'tagsDisplay', name: 'Labels', displayOnly: true});
        this.fields.unshift({visible: true, id: 'tagsExport', name: 'Labels', exportOnly: true});
        this.fields.unshift({visible: true, id: 'categoriesDisplay', name: 'Categories', displayOnly: true});
        this.fields.unshift({visible: true, id: 'categoriesExport', name: 'Categories', exportOnly: true});
        this.fields.unshift({visible: true, id: 'sku', name: 'Sku'});
        this.fields.unshift({visible: true, id: 'code', name: 'Product Code'});
        this.fields.unshift({visible: false, id: 'taxDisplay', name: 'Tax', displayOnly: true});
        this.fields.unshift({visible: false, id: 'taxExport', name: 'Tax', exportOnly: true});
        this.fields.unshift({visible: true, id: 'cost', name: 'Cost'});
        this.fields.unshift({visible: true, id: 'price', name: 'Price'});
        this.fields.unshift({visible: false, id: 'unitName', name: 'Price Unit Name'});
        this.fields.unshift({visible: false, id: 'priceType', name: 'Price Type'});
        this.fields.unshift({visible: false, id: 'alternateName', name: 'Alternate Name', exportOnly: true});
        this.fields.unshift({visible: false, id: 'nameExport', name: 'Name', exportOnly: true});
        this.fields.unshift({visible: true, id: 'nameDisplay', name: 'Name', displayOnly: true});
        this.fields.unshift({visible: true, id: 'select-checkbox', name: '&nbsp;'});
        this.fields.unshift({visible: false, id: 'data', name: 'data'});
        this.fields.push({visible: true, id: 'action', name: 'Action'});

        let dtOptions: DataTables.Settings = {};
        let dtPluginOptions = <any> dtOptions;
        //dtOptions.dom = 'B<"mt-1"frtip>';

        dtOptions.dom = 'f<"groupFilterContainer">Brtip';
        dtOptions.stateSave = true;
        dtOptions.columns = this.fields.map(f => {
            var res = {
                title: f.name,
                visible: !!f.visible
            };
            switch (f.id) {
                case 'select-checkbox':
                    res['class'] = 'select-checkbox nocolvis noexport';
                    res['sortable'] = false;
                    break;
                case 'data':
                    res['class'] = 'nocolvis noexport';
                    break;
                case 'action':
                    res['class'] = 'nocolvis noexport';
                    res['sortable'] = false;
                    break;
            }
            if (f.exportOnly) {
                res['class'] = 'nocolvis';
                res['visible'] = false;
            }
            if (f.displayOnly) {
                res['class'] = 'noexport';
            }
            return res;
        });

        dtOptions.createdRow = (row: Node, data: any[] | Object, index: number) => {
            const self = this;

            $(row).data('id', data[0]);
            $('td button', row).off('click').on('click', (event) => {
                event.preventDefault();
                let btn = $(event.target).closest('button');
                let tr = btn.closest('tr');
                let itemId = tr.data('id');
                let clickHandler = btn.data('click');
                self[clickHandler](itemId);
            });

            return row;
        };
        dtOptions.ajax = (dataTablesParameters: any, callback) => {
            console.log('dtOptions AJAX');
            this.itemsService.getItems().subscribe(items => {
                let data = items.map(c => {
                    var dtc = this.fields.map(f => {

                        switch (f.id) {
                            case 'data':
                                return c;
                            case 'price':
                            case 'cost':
                                return this.formatMoney(c[f.id]);
                            case 'priceType':
                                switch (c[f.id]) {
                                    case 'FIXED':
                                        return 'Fixed';
                                    case 'VARIABLE':
                                        return 'Variable';
                                    case 'PER_UNIT':
                                        return 'Per Unit';
                                }
                                return '';
                            case 'nameDisplay':
                                return c.name
                                    + (c.alternateName ? `<br/><small class="text-secondary">${c.alternateName}</small>` : '')
                                    + `<br/><small class="text-secondary">ID: ${c.id}</small>`;
                            case 'nameExport':
                                return c.name;
                            case 'taxDisplay':
                                if (c.defaultTaxRates) {
                                    return '<span class="text-secondary">Default</span>';
                                }
                                return c.taxRates.map(i => i.name).join('<br/>');
                            case 'taxExport':
                                if (c.defaultTaxRates) {
                                    return 'Default';
                                }
                                return c.taxRates.map(i => i.name).join(';');
                            case 'categoriesDisplay':
                                return c.categories.map(i => i.name).join('<br/>');
                            case 'categoriesExport':
                                return c.categories.map(i => i.name).join(';');
                            case 'tagsExport':
                                return c.tags.map(i => i.name).join(';');
                            case 'tagsDisplay':
                                return c.tags.map(i => i.name).join('<br/>');
                            case 'scanDataProgram':
                                return this.itemFieldCollections.scanDataPrograms.find(i => i.id == c.scanDataProgram).name;
                            case 'hidden':
                            case 'isRevenue':
                                return !!c[f.id] ? 'Yes' : 'No';
                            case 'action':
                                return `
                  <button type="button" class="btn" title="Edit"   data-click="editItem" ><i class="far fa-edit"></i></button>
                  <button type="button" class="btn" title="Copy"   data-click="copyItem" ><i class="far fa-copy"></i></button>
                  <button type="button" class="btn" title="Delete" data-click="deleteItem"><i class="far fa-trash-alt"></i></button>                  `;
                        }
                        if (f.nestedPropertyName) {
                            let obj = (c[f.nestedPropertyName] || [])[0] || {};//for now we can only display the first occurance
                            if (typeof obj === 'string') {
                                return obj;
                            }
                            return obj[f.id] || '';
                        }
                        return c[f.id] || (c.userFields.find(uf => uf.id === f.id) || {value: ''}).value || '';
                    });
                    return dtc;
                });
                callback({data: data});


            });

        };

        dtOptions.paging = true; // this.items.length > 10;

        dtOptions.preDrawCallback = () => {
            const dataTableSearchInputContainer = $('#dt_itemList_wrapper #dt_itemList_filter');
            const dataTableSearchInput = $('#dt_itemList_wrapper input[type=search]');
            const customSearchInput = $('#search');

            dataTableSearchInputContainer.hide();

            customSearchInput.attr('disabled', false as any);
            customSearchInput.on('input', () => {
                dataTableSearchInput.val(customSearchInput.val());
                dataTableSearchInput.trigger('input');
            });
        };


        //reorder
        dtPluginOptions.colReorder = true;
        dtPluginOptions.order = [[2, 'asc']];
        dtPluginOptions.select = {
            style: 'multi',
            selector: 'td:first-child'
        };
        dtPluginOptions.fixedHeader = true;

        var exportButtonCommon = {
            exportOptions: {
                // format: {
                //   body: function (data, row, column, node) {
                //     console.log(row);
                //     debugger;
                //     // Strip $ from salary column to make it numeric
                //     return column === 5 ?
                //       data.replace(/[$,]/g, '') :
                //       data;
                //   }
                // },

                columns: ':not(.noexport)'
            }
        };
        //bvisibility
        dtPluginOptions.buttons = [
            $.extend(true, {}, exportButtonCommon, {extend: 'copyHtml5'}),
            $.extend(true, {}, exportButtonCommon, {extend: 'excelHtml5'}),
            $.extend(true, {}, exportButtonCommon, {extend: 'csvHtml5'}),
            {extend: 'pdfHtml5', exportOptions: {columns: ':visible:not(.noexport)'}},
            {extend: 'print', exportOptions: {columns: ':visible:not(.noexport)'}},
            {extend: 'colvis', columns: ':not(.nocolvis)'},
        ];

        return dtOptions;
    }

    editSelected() {
        this.editSelectedClicked = true;
        this.router.navigate(['/inventory/edit-selected']);
    }

    editItem(item: ItemEx): void {
        let navigateTo = `/inventory/${item.id}`;
        this.router.navigate([navigateTo]);

    }

    copyItem(item: ItemEx): void {
        let navigateTo = `/inventory/${item.id}`;
        this.router.navigate([navigateTo], {queryParams: {copy: true}});
    }

    deleteItem(item: ItemEx): void {
        if (!confirm(`Are you sure you want to delete ${item.name}?`)) {
            return;
        }
        this.itemsService.deleteItem(item.code).subscribe(_ => _);
    }

    formatMoney(value: any): string {
        const formattedValue = (value / 100).toFixed(2);
        return `$${formattedValue}`;
    }

    rerender(): void {

    }

    filterOnSelect(val: TypeaheadMatch) {
        console.log(`Enter filterOnSelect:${val.item.filterType}`);
        if (!val.item) {
            return;
        }

        let queryParams = {}; // Initialize an empty queryParams object

        switch (val.item.filterType) {
            case 'All Categories':
                // No need to add any queryParams for this case
                break;
            case 'Scan Data Program':
                queryParams = {scanDataProgramId: val.item.id};
                break;
            case 'Category':
                queryParams = {categoryId: val.item.id};
                break;
        }

        // Update the query parameters and then navigate
        this.router.navigate([], {relativeTo: this.route, queryParams: queryParams}).then(() => {
            // After navigation, you can update the table data
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.ajax.reload(); // Reload the table data
            });
        });
    }

    ngOnDestroy(): void {
        console.log("Unsubscribe");
        this.itemsSubscription.unsubscribe();
        this.dtTrigger.unsubscribe();

        const customSearchInput = $('#search');
        customSearchInput.off('input');
    }
}
