import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Merchant} from "./model/merchant";
import {AuthService} from './auth.service';
import {ENV} from "../environments/env";

@Injectable({
  providedIn: 'root'
})
export class IdSrvService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  getMerchants(): Observable<Merchant[]> {
    const headers = this.authService.getBearerHeader();
    return this.http.get<Merchant[]>(ENV.apiBaseUrl + "/internal-admin-api/merchants", { headers });
  }
}
